import axiosInstance from './axiosInstance';


const fetcher = (url, params = {}) => {
  return axiosInstance({
    url,
    params,
  }).then(
    res => res.data
  )
};

export default fetcher;
