import { createTheme, responsiveFontSizes } from '@mui/material/styles';


let theme = createTheme({
  palette: {
    primary: {
      main: '#133546',
      light: '#1a97a7',
      dark: '#0b2837',
      contrastText: "#fff"
    },
    secondary: {
      main: '#f19430',
      contrastText: "#fff"
    },
    info: {
      main: '#1a97a7',
    },
    background: {
      default: '#ededed',
    },
  },
  typography: {
    button: {
      textTransform: "none"
    }
  }
});

theme = responsiveFontSizes(theme);

export default theme;
