import axios from "axios";


const axiosInstance = axios.create({
  baseURL: process.env.DOMAIN,
  xsrfHeaderName: 'X-CSRFToken',
  xsrfCookieName: 'csrftoken'
});

export default axiosInstance;
