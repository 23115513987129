import React, { createContext, useContext, useEffect } from 'react'
import { useRouter } from 'next/router';

import axiosInstance from '../lib/axiosInstance';

import useSWR from 'swr';


const AuthContext = createContext({});


export const AuthProvider = ({ children }) => {
  const router = useRouter();
  const { data: user, error, mutate } = useSWR('/api/v2/user-basic/');

  const emailLogin = async (values) => {
    const { data } = await axiosInstance.post(
      `/api/v2/login/`,
      values
    );

    if (data) {
      mutate(data.user);
      if (data.user.is_business) {
        router.push('/hub/');
      }
    }
  }

  const googleLogin = async (token) => {
    const { data } = await axiosInstance.post(
      `/api/v2/google-login/`,
      { token }
    );
    if (data) {
      mutate(data.user);
      if (data.user.is_business) {
        router.push('/hub/');
      }
    }
  }

  const facebookLogin = async (token) => {
    const { data } = await axiosInstance.post(
      `/api/v2/facebook-login/`,
      { token }
    );
    if (data) {
      mutate(data.user);
      if (data.user.is_business) {
        router.push('/hub/');
      }
    }
  }

  const verifyEmail = async (key) => {
    const { data } = await axiosInstance.post(
      `/api/v2/verify-email/`,
      {key}
    )

    if (data) {
      mutate(data.user);
      if (data.user.is_business) {
        router.push('/hub/onboard/');
      } else {
        router.push('/dash/');
      }
    }
  }

  const appKeyAuth = async (key) => {
    const { data } = await axiosInstance.post(
      '/api/v2/app-auth/',
      {key}
    )
    if (data) {
      mutate(data.user);
    }
  }

  const resetPassword = async (values) => {
    const { data } = await axiosInstance.post(
      `/api/v2/reset-password-verify/`,
      values
    )
    if (data) {
      mutate(data.user);
      if (data.user.is_business) {
        router.push('/hub/');
      }
    }
  }

  const logout = async () => {
    await axiosInstance.post(`/api/v2/logout/`);
    mutate('/api/v2/user-basic/', null);
    window.location = '/';
  }

  const loginAs = async (values) => {
    const { data } = await axiosInstance.post(
      `/api/v2/login-as/`,
      values
    );
    const { user } = data;
    mutate({ ...user });
  }

  return (
    <AuthContext.Provider value={{
      user,
      loading: !error && !user,
      isAuthenticated: !!user && !error,
      emailLogin,
      googleLogin,
      facebookLogin,
      verifyEmail,
      appKeyAuth,
      resetPassword,
      logout,
      loginAs
    }}>
      {children}
    </AuthContext.Provider>
  )
}


export default function useAuth() {
  const context = useContext(AuthContext)
  return context
};


export function ProtectRoute(Component) {
  return function Protect () {
    const { isAuthenticated, loading } = useAuth();
    const router = useRouter();

    useEffect(() => {
      if (!isAuthenticated && !loading) {
        router.push(`/login/?next=${router.pathname}`);
      }
    }, [isAuthenticated, loading, router])

    return (<Component {...arguments} />)
  }
}


export function NoAuthRoute(Component) {
  return function NoAuth() {
    const { isAuthenticated, loading } = useAuth();
    const router = useRouter();

    useEffect(() => {
      if (isAuthenticated && !loading) {
        const next = router.query['next'] || '/'
        router.push(next);
      }
    }, [isAuthenticated, loading, router])

    return (<Component {...arguments} />)
  }
}
